<!-- =========================================================================================
	File Name: ItemGridView.vue
	Description: Item Component - Grid VIew
	----------------------------------------------------------------------------------------
	Item Name: Vuesax Admin - VueJS Dashboard Admin Template
	Author: Pixinvent
	Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vs-card>
    <div slot="header">
      <h5 class="flex items-center">
        {{ item.contactName }} -
        <span
          v-if="item.type === 'NPS'"
          :class="{
            'text-danger': item.score <= 6,
            'text-grey': item.score > 6 && item.score < 9,
            'text-success': item.score > 8
          }"
          class="ml-3"
        >
          {{ item.score }}/10
        </span>
        <feather-icon
          v-if="item.type === 'CSAT' && item.score === 0"
          icon="FrownIcon"
          svgClasses="w-6 h-6 text-danger"
          class="ml-3"
        />
        <feather-icon
          v-if="item.type === 'CSAT' && item.score === 1"
          icon="MehIcon"
          svgClasses="w-6 h-6"
          class="ml-3"
        />
        <feather-icon
          v-if="item.type === 'CSAT' && item.score === 2"
          icon="SmileIcon"
          svgClasses="w-6 h-6 text-success"
          class="ml-3"
        />

        <vs-chip
          v-for="intention in item.intentions"
          :key="intention"
          class="ml-3"
          color="primary"
          transparent
        >
          {{ intention | capitalize }}
        </vs-chip>
        <vs-chip
          v-for="(tag, index) in item.tags"
          :key="index"
          class="ml-3"
        >
          {{ tag.label | capitalize }}: {{ tag.value | capitalize }}
        </vs-chip>
      </h5>
    </div>
    <vs-row>
      <vs-col vs-w="12" class="mt-3" v-if="item.message.length > 0">
        <p class="text-base">
          {{ item.message }}
        </p>
      </vs-col>
      <vs-col vs-w="12" vs-type="flex" vs-align="center" vs-justify="end" class="mt-5">
        <div>
          <p class="text-base mr-2">
              {{ item.type }}
            -
            {{ item.chatName }}
            -
            {{ toDateSmall(item.createdAt) }}
            -
            <span v-if="item.closingOperatorName" class="font-semibold">{{ item.closingOperatorName }}</span>
          </p>
        </div>
        <vs-button
          @click="$emit('seeOnInbox')"
          icon-pack="feather"
          icon="icon-send"
          icon-after
          class="px-2 ml-3"
        >
          {{ $t("ViewInInbox") }}
        </vs-button>
      </vs-col>
    </vs-row>
  </vs-card>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    seeOnInbox: {
      type: Function,
      required: false
    }
  },
  methods: {
    toDateSmall(time) {
      let fullDate = new Date(time);
      let valid = new Date(time).getTime() > 0;
      if (!valid) fullDate = new Date(time);
      const dd = String(fullDate.getDate()).padStart(2, "0");
      const mm = String(fullDate.getMonth() + 1).padStart(2, "0");
      const yy = String(fullDate.getFullYear()).substring(2, 4);
      let hour = fullDate.getHours() + ":";
      if (fullDate.getMinutes() < 10) hour += "0" + fullDate.getMinutes();
      else hour += fullDate.getMinutes();
      return `${dd}/${mm}/${yy} ${hour}`;
    },
  }
};
</script>
